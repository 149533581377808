<template>
  <div>
    <modal-services-d-s-third-step
      ref="questionnaire"
      :sales-client="salesClient"
      :type-modal="6"
      :from-ds-questionnaire="true"
      @saved="savedQuestionnaire"
    />
    <div class="d-flex justify-content-center">
      <b-button variant="primary" :disabled="isResponsibleAccount" @click="saveQuestionnaire">Save</b-button>
    </div>
  </div>
</template>

<script>
import ModalServicesDSThirdStep from "@/views/crm/views/sales-made/components/modals/services/debt-solution/ModalServicesDSThirdStep.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "DsQuestionnaire",
  components: {
    ModalServicesDSThirdStep,
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
  data() {
    return {
      salesClient: {
        event_id: "",
        account_id: "",
        haveQuestionnaire: "",
      },
    };
  },
  created() {
    this.isResponsible();
    this.salesClient.event_id = this.client.event_id;
    this.salesClient.account_id = this.client.id;
    this.salesClient.haveQuestionnaire = this.client.haveQuestionnaire;
  },
  methods: {
    isResponsible() {
      this.$store.dispatch('NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO', { account_id: this.$route.params.idClient });
    },
    async saveQuestionnaire() {
      await this.$refs.questionnaire.axiosNext(null, 3);
    },
    setData() {
      this.salesClient.event_id = this.client.event_id;
      this.salesClient.account_id = this.client.id;
      this.salesClient.haveQuestionnaire = this.client.haveQuestionnaire;
    },
    async savedQuestionnaire() {
      this.showSuccessSwal();
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
      ]);
    },
  },
  watch: {
    client(newValue, oldValue) {
      if (newValue) {
        this.setData();
      }
    },
  },
};
</script>

<style scoped>
</style>